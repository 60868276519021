/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetImagePosDto
 */
export interface GetImagePosDto {
    /**
     * 
     * @type {string}
     * @memberof GetImagePosDto
     */
    url: string;
}

export function GetImagePosDtoFromJSON(json: any): GetImagePosDto {
    return GetImagePosDtoFromJSONTyped(json, false);
}

export function GetImagePosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetImagePosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
    };
}

export function GetImagePosDtoToJSON(value?: GetImagePosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
    };
}


