/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImagePos
 */
export interface ImagePos {
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    original_name: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    mimetype: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    extension: ImagePosExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ImagePos
     */
    updated_at?: string;
}

/**
* @export
* @enum {string}
*/
export enum ImagePosExtensionEnum {
    Png = 'png',
    Jpeg = 'jpeg',
    Jpg = 'jpg'
}

export function ImagePosFromJSON(json: any): ImagePos {
    return ImagePosFromJSONTyped(json, false);
}

export function ImagePosFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImagePos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': json['key'],
        'original_name': json['original_name'],
        'mimetype': json['mimetype'],
        'filename': json['filename'],
        'extension': json['extension'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'created_at': json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function ImagePosToJSON(value?: ImagePos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'original_name': value.original_name,
        'mimetype': value.mimetype,
        'filename': value.filename,
        'extension': value.extension,
        'url': value.url,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}


