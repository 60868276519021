import React from 'react';

import { Flex, Text, HStack } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';
import BackofficePOSModal from './components/BackofficePOSModal';

import {
	SellersApi,
	PosApi,
	ListPosRequest,
	ListPosStatusEnum,
	ListSellerPaginatedPosRequest,
	ListSellerPaginatedPosSortEnum,
	ListSellerPaginatedPosStatusEnum,
} from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

const BackofficePOS: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const posApi = new PosApi(apiConfig);
	const sellerApi = new SellersApi(apiConfig);

	const { isBackoffice } = useAuth();
	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	const [isLoading, setIsLoading] = React.useState(true);
	const [resetTable, setResetTable] = React.useState(false);
	const [openPosModal, setOpenUserModal] = React.useState(false);
	const [posID, setPosID] = React.useState('');
	const [selectedPos, setSelectedPos] = React.useState(undefined);

	const [posList, setPosList] = React.useState<Array<string>>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);

	async function fetchListPosRequest() {
		setIsLoading(true);

		if (currentSeller) {
			try {
				const requestParams: ListSellerPaginatedPosRequest = {
					sellerId: currentSeller?.id!,
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					status: ListSellerPaginatedPosStatusEnum.Active,
					sort: ListSellerPaginatedPosSortEnum.Asc,
				};

				const response = await sellerApi.listSellerPaginatedPos(requestParams);

				setPosList(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListPosRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					status: ListPosStatusEnum.Active,
				};

				const response = await posApi.listPos(requestParams);

				setPosList(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleOpenPosModal = (pos?) => {
		if (pos) {
			setPosID(pos.id);
			setSelectedPos(pos);
			setOpenUserModal(!openPosModal);
			return;
		}

		setPosID('');
		setSelectedPos(undefined);
		setOpenUserModal(!openPosModal);
	};

	React.useEffect(() => {
		fetchListPosRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination, resetTable]);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />
			<HStack justify='space-between'>
				<Text id='pos-text' color={`gray.700`} fontSize={`lg`} fontWeight={`bold`}>
					Máquinas
				</Text>

				<Button
					id='open-pos-modal-button'
					title={`Você deve selecionar um estabelecimento`}
					isDisabled={!currentSeller}
					bgColor='primary'
					onClick={() => {
						handleOpenPosModal();
					}}
				>
					Criar Solicitação
				</Button>
			</HStack>
			<Paper id='backoffice-pagination-table-paper-text'>
				<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
					{posList && (
						<SampleTable
							data={posList}
							setPagination={setPagination}
							pagination={pagination}
							totalPages={totalPages}
							handleOpenPosModal={handleOpenPosModal}
						/>
					)}
				</Flex>
			</Paper>
			{
				<BackofficePOSModal
					seller={currentSeller}
					showSellerInput={isBackoffice}
					openPosModal={openPosModal}
					handleOpenPosModal={handleOpenPosModal}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					resetTable={resetTable}
					setResetTable={setResetTable}
					posID={posID}
					selectedPos={selectedPos}
				/>
			}
		</Flex>
	);
};

export default BackofficePOS;
