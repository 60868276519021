/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfirmPaymentBadRequestDto,
    ConfirmPaymentBadRequestDtoFromJSON,
    ConfirmPaymentBadRequestDtoToJSON,
    ConfirmPaymentDto,
    ConfirmPaymentDtoFromJSON,
    ConfirmPaymentDtoToJSON,
    CreateExportSellersListDto,
    CreateExportSellersListDtoFromJSON,
    CreateExportSellersListDtoToJSON,
    CreatePayerDto,
    CreatePayerDtoFromJSON,
    CreatePayerDtoToJSON,
    CreateSaleAutoGeneratePaymentLinkDto,
    CreateSaleAutoGeneratePaymentLinkDtoFromJSON,
    CreateSaleAutoGeneratePaymentLinkDtoToJSON,
    CreateSaleDto,
    CreateSaleDtoFromJSON,
    CreateSaleDtoToJSON,
    CreateSellerCredentialDto,
    CreateSellerCredentialDtoFromJSON,
    CreateSellerCredentialDtoToJSON,
    CreateSellerCredentialResponseDto,
    CreateSellerCredentialResponseDtoFromJSON,
    CreateSellerCredentialResponseDtoToJSON,
    CreateSellerDto,
    CreateSellerDtoFromJSON,
    CreateSellerDtoToJSON,
    CreateSellerPosDto,
    CreateSellerPosDtoFromJSON,
    CreateSellerPosDtoToJSON,
    CreateSellerUserDto,
    CreateSellerUserDtoFromJSON,
    CreateSellerUserDtoToJSON,
    CreateSellerV2Dto,
    CreateSellerV2DtoFromJSON,
    CreateSellerV2DtoToJSON,
    CreateSplitRulesDto,
    CreateSplitRulesDtoFromJSON,
    CreateSplitRulesDtoToJSON,
    EditSellerCredentialDto,
    EditSellerCredentialDtoFromJSON,
    EditSellerCredentialDtoToJSON,
    EditSellerDto,
    EditSellerDtoFromJSON,
    EditSellerDtoToJSON,
    EditSellerPartialDto,
    EditSellerPartialDtoFromJSON,
    EditSellerPartialDtoToJSON,
    GetAcquirerDto,
    GetAcquirerDtoFromJSON,
    GetAcquirerDtoToJSON,
    GetBilletDto,
    GetBilletDtoFromJSON,
    GetBilletDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    GetImagePosDto,
    GetImagePosDtoFromJSON,
    GetImagePosDtoToJSON,
    GetLogoDto,
    GetLogoDtoFromJSON,
    GetLogoDtoToJSON,
    GetMerchantSellerDto,
    GetMerchantSellerDtoFromJSON,
    GetMerchantSellerDtoToJSON,
    GetPayerDto,
    GetPayerDtoFromJSON,
    GetPayerDtoToJSON,
    GetSaleDto,
    GetSaleDtoFromJSON,
    GetSaleDtoToJSON,
    GetSaleSummaryChartDto,
    GetSaleSummaryChartDtoFromJSON,
    GetSaleSummaryChartDtoToJSON,
    GetSaleSummaryDto,
    GetSaleSummaryDtoFromJSON,
    GetSaleSummaryDtoToJSON,
    GetSaleWithAcquirerDocumentDto,
    GetSaleWithAcquirerDocumentDtoFromJSON,
    GetSaleWithAcquirerDocumentDtoToJSON,
    GetSellerAcceptedPaymentMethodsDto,
    GetSellerAcceptedPaymentMethodsDtoFromJSON,
    GetSellerAcceptedPaymentMethodsDtoToJSON,
    GetSellerCredentialResponseDto,
    GetSellerCredentialResponseDtoFromJSON,
    GetSellerCredentialResponseDtoToJSON,
    GetSellerDto,
    GetSellerDtoFromJSON,
    GetSellerDtoToJSON,
    GetSellerPaymentProviderDto,
    GetSellerPaymentProviderDtoFromJSON,
    GetSellerPaymentProviderDtoToJSON,
    GetSellerPosDto,
    GetSellerPosDtoFromJSON,
    GetSellerPosDtoToJSON,
    GetSellerReportCustomizationDto,
    GetSellerReportCustomizationDtoFromJSON,
    GetSellerReportCustomizationDtoToJSON,
    GetSellerResponsibleSuportDto,
    GetSellerResponsibleSuportDtoFromJSON,
    GetSellerResponsibleSuportDtoToJSON,
    GetSellerSaleAutoGeneratePaymentLinkDto,
    GetSellerSaleAutoGeneratePaymentLinkDtoFromJSON,
    GetSellerSaleAutoGeneratePaymentLinkDtoToJSON,
    GetSellerSaleDto,
    GetSellerSaleDtoFromJSON,
    GetSellerSaleDtoToJSON,
    GetSellerUserDto,
    GetSellerUserDtoFromJSON,
    GetSellerUserDtoToJSON,
    IncludeAcquirerDto,
    IncludeAcquirerDtoFromJSON,
    IncludeAcquirerDtoToJSON,
    IncludeSalePlanDto,
    IncludeSalePlanDtoFromJSON,
    IncludeSalePlanDtoToJSON,
    NotaryOffice,
    NotaryOfficeFromJSON,
    NotaryOfficeToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    SearchSellerDto,
    SearchSellerDtoFromJSON,
    SearchSellerDtoToJSON,
    SplitRulesDto,
    SplitRulesDtoFromJSON,
    SplitRulesDtoToJSON,
    UpdateSellerThemeDto,
    UpdateSellerThemeDtoFromJSON,
    UpdateSellerThemeDtoToJSON,
    UpdateSellerUserDto,
    UpdateSellerUserDtoFromJSON,
    UpdateSellerUserDtoToJSON,
} from '../models';

export interface AddAcquirerRequest {
    sellerId: string;
    includeAcquirerDto: IncludeAcquirerDto;
}

export interface AddSalePlanRequest {
    sellerId: string;
    includeSalePlanDto: IncludeSalePlanDto;
}

export interface ConfirmSalesRequest {
    sellerId: string;
    confirmPaymentDto: ConfirmPaymentDto;
}

export interface CreateSaleAutoGenerateLinkPaymentRequest {
    sellerId: string;
    createSaleAutoGeneratePaymentLinkDto: CreateSaleAutoGeneratePaymentLinkDto;
}

export interface CreateSellerRequest {
    createSellerDto: CreateSellerDto;
}

export interface CreateSellerCredentialRequest {
    sellerId: string;
    createSellerCredentialDto: CreateSellerCredentialDto;
}

export interface CreateSellerPayerRequest {
    sellerId: string;
    createPayerDto: CreatePayerDto;
}

export interface CreateSellerPosRequest {
    sellerId: string;
    createSellerPosDto: CreateSellerPosDto;
}

export interface CreateSellerSaleRequest {
    sellerId: string;
    createSaleDto: CreateSaleDto;
}

export interface CreateSellerUserRequest {
    sellerId: string;
    createSellerUserDto: CreateSellerUserDto;
}

export interface CreateSellerV2Request {
    createSellerV2Dto: CreateSellerV2Dto;
}

export interface CreateSellersListExportRequestRequest {
    createExportSellersListDto: CreateExportSellersListDto;
}

export interface DeleteSellerCredentialRequest {
    sellerId: string;
    credentialId: string;
}

export interface EditSellerRequest {
    sellerId: string;
    editSellerDto: EditSellerDto;
}

export interface EditSellerCredentialRequest {
    sellerId: string;
    credentialId: string;
    editSellerCredentialDto: EditSellerCredentialDto;
}

export interface EditSellerPartialRequest {
    sellerId: string;
    editSellerPartialDto: EditSellerPartialDto;
}

export interface GetAcceptedPaymentMethodsBySellerIdRequest {
    sellerId: string;
    valueCents?: number;
}

export interface GetAllSellerSalesSummaryChartRequest {
    sellerId: string;
}

export interface GetNotarysOfficesByCnsRequest {
    cns: string;
}

export interface GetReportCustomizationBySellerIdRequest {
    sellerId: string;
}

export interface GetSaleByServiceIdRequest {
    sellerId: string;
    serviceId: string;
}

export interface GetSellerRequest {
    sellerId: string;
}

export interface GetSellerByCodeRequest {
    codeId: string;
}

export interface GetSellerByTerminalIdRequest {
    terminalId: string;
}

export interface GetSellerCredentialRequest {
    sellerId: string;
    credentialId: string;
}

export interface GetSellerPaymentProviderRequest {
    sellerId: string;
}

export interface GetSellerPosRequest {
    sellerId: string;
    sellerPosId: string;
}

export interface GetSellerSaleRequest {
    sellerId: string;
    saleId: string;
}

export interface GetSellerSaleBilletRequest {
    sellerId: string;
    saleId: string;
}

export interface GetSellerSalesSummaryRequest {
    sellerId: string;
    startDate?: string;
    endDate?: string;
    status?: GetSellerSalesSummaryStatusEnum;
}

export interface GetSellerSalev2Request {
    sellerId: string;
    saleId: string;
}

export interface GetSellersByResponsibleIdRequest {
    responsibleId: string;
}

export interface InternalListSellerSalesRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    description?: string;
    search?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<InternalListSellerSalesTypeEnum>;
    name?: string;
    payerName?: string;
    user?: string;
    formPayment?: Array<string>;
    startDate?: string;
    endDate?: string;
    lastNumberSales?: number;
    paymentDay?: boolean;
    payerEmail?: string;
    payerPhone?: string;
    updatedDay?: boolean;
    createdByUserName?: string;
    pos?: Array<string>;
    calcTotalAmount?: boolean;
    sort?: InternalListSellerSalesSortEnum;
    id?: string;
    status?: Array<InternalListSellerSalesStatusEnum>;
}

export interface ListMerchantSellersRequest {
    sellerId: string;
    text?: string;
}

export interface ListPosSellerSalesRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    terminalId: string;
    description?: string;
    search?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<ListPosSellerSalesTypeEnum>;
    name?: string;
    payerName?: string;
    user?: string;
    formPayment?: Array<string>;
    startDate?: string;
    endDate?: string;
    lastNumberSales?: number;
    paymentDay?: boolean;
    payerEmail?: string;
    payerPhone?: string;
    settlementBatchDay?: string;
    updatedDay?: boolean;
    createdByUserName?: string;
    sort?: ListPosSellerSalesSortEnum;
    id?: string;
    status?: Array<ListPosSellerSalesStatusEnum>;
}

export interface ListSellerAcquirersRequest {
    sellerId: string;
}

export interface ListSellerBilletsRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    sort?: ListSellerBilletsSortEnum;
}

export interface ListSellerCredentialRequest {
    sellerId: string;
}

export interface ListSellerPaginatedPosRequest {
    limit: number;
    currentPage: number;
    status: ListSellerPaginatedPosStatusEnum;
    sellerId: string;
    sort?: ListSellerPaginatedPosSortEnum;
}

export interface ListSellerPayerRequest {
    sellerId: string;
    payerDocument: string;
}

export interface ListSellerPayersRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    sort?: ListSellerPayersSortEnum;
    name?: string;
    document?: string;
}

export interface ListSellerPaymentLinksRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    startDate?: string;
    endDate?: string;
    id?: string;
    email?: string;
    description?: string;
    payerName?: string;
    status?: Array<ListSellerPaymentLinksStatusEnum>;
    formPayment?: Array<ListSellerPaymentLinksFormPaymentEnum>;
}

export interface ListSellerPosRequest {
    sellerId: string;
}

export interface ListSellerProtestLinksRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    startDate?: string;
    endDate?: string;
    id?: string;
    email?: string;
    status?: Array<ListSellerProtestLinksStatusEnum>;
    formPayment?: Array<ListSellerProtestLinksFormPaymentEnum>;
}

export interface ListSellerResponsibleSupportByIdRequest {
    sellerId: string;
}

export interface ListSellerSalesRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    description?: string;
    search?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<ListSellerSalesTypeEnum>;
    name?: string;
    payerName?: string;
    user?: string;
    formPayment?: Array<string>;
    startDate?: string;
    endDate?: string;
    lastNumberSales?: number;
    paymentDay?: boolean;
    payerEmail?: string;
    payerPhone?: string;
    settlementBatchDay?: string;
    updatedDay?: boolean;
    createdByUserName?: string;
    sort?: ListSellerSalesSortEnum;
    id?: string;
    status?: Array<ListSellerSalesStatusEnum>;
}

export interface ListSellerSalesV2Request {
    limit: number;
    currentPage: number;
    sellerId: string;
    description?: string;
    search?: string;
    amount?: number;
    originalAmount?: number;
    type?: Array<ListSellerSalesV2TypeEnum>;
    name?: string;
    payerName?: string;
    user?: string;
    formPayment?: Array<string>;
    startDate?: string;
    endDate?: string;
    lastNumberSales?: number;
    paymentDay?: boolean;
    payerEmail?: string;
    payerPhone?: string;
    settlementBatchDay?: string;
    updatedDay?: boolean;
    createdByUserName?: string;
    sort?: ListSellerSalesV2SortEnum;
    id?: string;
    status?: Array<ListSellerSalesV2StatusEnum>;
}

export interface ListSellerUsersRequest {
    limit: number;
    currentPage: number;
    sellerId: string;
    sort?: ListSellerUsersSortEnum;
}

export interface ListSellersRequest {
    limit: number;
    currentPage: number;
    sort?: ListSellersSortEnum;
    text?: string;
    status?: Array<ListSellersStatusEnum>;
    document?: string;
    name?: string;
    sellerId?: string;
    mode?: ListSellersModeEnum;
    billetProvider?: ListSellersBilletProviderEnum;
    cns?: string;
    code?: string;
}

export interface NewConfirmSalesRequest {
    sellerId: string;
    confirmPaymentDto: ConfirmPaymentDto;
}

export interface SearchSellerRequest {
    search?: string;
}

export interface SplitSaleRequest {
    sellerId: string;
    saleId: string;
    createSplitRulesDto: Array<CreateSplitRulesDto>;
}

export interface UpdateSellerThemeRequest {
    sellerId: string;
    updateSellerThemeDto: UpdateSellerThemeDto;
}

export interface UpdateSellerUserRequest {
    sellerId: string;
    userId: string;
    updateSellerUserDto: UpdateSellerUserDto;
}

export interface UploadSellerImagePosRequest {
    sellerId: string;
    file: Blob;
}

export interface UploadSellerLogoRequest {
    sellerId: string;
    file: Blob;
}

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     * Associar uma adquirente a um estabelecimento
     */
    async addAcquirerRaw(requestParameters: AddAcquirerRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling addAcquirer.');
        }

        if (requestParameters.includeAcquirerDto === null || requestParameters.includeAcquirerDto === undefined) {
            throw new runtime.RequiredError('includeAcquirerDto','Required parameter requestParameters.includeAcquirerDto was null or undefined when calling addAcquirer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/acquirers`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncludeAcquirerDtoToJSON(requestParameters.includeAcquirerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Associar uma adquirente a um estabelecimento
     */
    async addAcquirer(requestParameters: AddAcquirerRequest): Promise<GetSellerDto> {
        const response = await this.addAcquirerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Associar um plano de vendas a um estabelecimento
     */
    async addSalePlanRaw(requestParameters: AddSalePlanRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling addSalePlan.');
        }

        if (requestParameters.includeSalePlanDto === null || requestParameters.includeSalePlanDto === undefined) {
            throw new runtime.RequiredError('includeSalePlanDto','Required parameter requestParameters.includeSalePlanDto was null or undefined when calling addSalePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales-plans`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncludeSalePlanDtoToJSON(requestParameters.includeSalePlanDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Associar um plano de vendas a um estabelecimento
     */
    async addSalePlan(requestParameters: AddSalePlanRequest): Promise<GetSellerDto> {
        const response = await this.addSalePlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Em alternativa a esta rota, utilize a [v2](#/Sellers/newConfirmSales)
     * Autorizar liquidação das vendas que estão aguardando confirmação
     */
    async confirmSalesRaw(requestParameters: ConfirmSalesRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling confirmSales.');
        }

        if (requestParameters.confirmPaymentDto === null || requestParameters.confirmPaymentDto === undefined) {
            throw new runtime.RequiredError('confirmPaymentDto','Required parameter requestParameters.confirmPaymentDto was null or undefined when calling confirmSales.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/confirm`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPaymentDtoToJSON(requestParameters.confirmPaymentDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Em alternativa a esta rota, utilize a [v2](#/Sellers/newConfirmSales)
     * Autorizar liquidação das vendas que estão aguardando confirmação
     */
    async confirmSales(requestParameters: ConfirmSalesRequest): Promise<string> {
        const response = await this.confirmSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma venda com status pendente para um estabelecimento pelo link auto gerável
     */
    async createSaleAutoGenerateLinkPaymentRaw(requestParameters: CreateSaleAutoGenerateLinkPaymentRequest): Promise<runtime.ApiResponse<GetSellerSaleAutoGeneratePaymentLinkDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSaleAutoGenerateLinkPayment.');
        }

        if (requestParameters.createSaleAutoGeneratePaymentLinkDto === null || requestParameters.createSaleAutoGeneratePaymentLinkDto === undefined) {
            throw new runtime.RequiredError('createSaleAutoGeneratePaymentLinkDto','Required parameter requestParameters.createSaleAutoGeneratePaymentLinkDto was null or undefined when calling createSaleAutoGenerateLinkPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/payment-link-auto`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSaleAutoGeneratePaymentLinkDtoToJSON(requestParameters.createSaleAutoGeneratePaymentLinkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerSaleAutoGeneratePaymentLinkDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma venda com status pendente para um estabelecimento pelo link auto gerável
     */
    async createSaleAutoGenerateLinkPayment(requestParameters: CreateSaleAutoGenerateLinkPaymentRequest): Promise<GetSellerSaleAutoGeneratePaymentLinkDto> {
        const response = await this.createSaleAutoGenerateLinkPaymentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar estabelecimento
     */
    async createSellerRaw(requestParameters: CreateSellerRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.createSellerDto === null || requestParameters.createSellerDto === undefined) {
            throw new runtime.RequiredError('createSellerDto','Required parameter requestParameters.createSellerDto was null or undefined when calling createSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerDtoToJSON(requestParameters.createSellerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Criar estabelecimento
     */
    async createSeller(requestParameters: CreateSellerRequest): Promise<GetSellerDto> {
        const response = await this.createSellerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar uma nova credencial para o estabelecimento
     */
    async createSellerCredentialRaw(requestParameters: CreateSellerCredentialRequest): Promise<runtime.ApiResponse<CreateSellerCredentialResponseDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerCredential.');
        }

        if (requestParameters.createSellerCredentialDto === null || requestParameters.createSellerCredentialDto === undefined) {
            throw new runtime.RequiredError('createSellerCredentialDto','Required parameter requestParameters.createSellerCredentialDto was null or undefined when calling createSellerCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/credentials`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerCredentialDtoToJSON(requestParameters.createSellerCredentialDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSellerCredentialResponseDtoFromJSON(jsonValue));
    }

    /**
     * Criar uma nova credencial para o estabelecimento
     */
    async createSellerCredential(requestParameters: CreateSellerCredentialRequest): Promise<CreateSellerCredentialResponseDto> {
        const response = await this.createSellerCredentialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar pagador do estabelecimento
     */
    async createSellerPayerRaw(requestParameters: CreateSellerPayerRequest): Promise<runtime.ApiResponse<CreatePayerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerPayer.');
        }

        if (requestParameters.createPayerDto === null || requestParameters.createPayerDto === undefined) {
            throw new runtime.RequiredError('createPayerDto','Required parameter requestParameters.createPayerDto was null or undefined when calling createSellerPayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/payers`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePayerDtoToJSON(requestParameters.createPayerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePayerDtoFromJSON(jsonValue));
    }

    /**
     * Criar pagador do estabelecimento
     */
    async createSellerPayer(requestParameters: CreateSellerPayerRequest): Promise<CreatePayerDto> {
        const response = await this.createSellerPayerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Incluir um POS em um estabelecimento
     */
    async createSellerPosRaw(requestParameters: CreateSellerPosRequest): Promise<runtime.ApiResponse<GetSellerPosDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerPos.');
        }

        if (requestParameters.createSellerPosDto === null || requestParameters.createSellerPosDto === undefined) {
            throw new runtime.RequiredError('createSellerPosDto','Required parameter requestParameters.createSellerPosDto was null or undefined when calling createSellerPos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/pos`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerPosDtoToJSON(requestParameters.createSellerPosDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerPosDtoFromJSON(jsonValue));
    }

    /**
     * Incluir um POS em um estabelecimento
     */
    async createSellerPos(requestParameters: CreateSellerPosRequest): Promise<GetSellerPosDto> {
        const response = await this.createSellerPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar venda para um estabelecimento
     */
    async createSellerSaleRaw(requestParameters: CreateSellerSaleRequest): Promise<runtime.ApiResponse<GetSellerSaleDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerSale.');
        }

        if (requestParameters.createSaleDto === null || requestParameters.createSaleDto === undefined) {
            throw new runtime.RequiredError('createSaleDto','Required parameter requestParameters.createSaleDto was null or undefined when calling createSellerSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSaleDtoToJSON(requestParameters.createSaleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerSaleDtoFromJSON(jsonValue));
    }

    /**
     * Realizar venda para um estabelecimento
     */
    async createSellerSale(requestParameters: CreateSellerSaleRequest): Promise<GetSellerSaleDto> {
        const response = await this.createSellerSaleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar usuário do estabelecimento
     */
    async createSellerUserRaw(requestParameters: CreateSellerUserRequest): Promise<runtime.ApiResponse<GetSellerUserDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createSellerUser.');
        }

        if (requestParameters.createSellerUserDto === null || requestParameters.createSellerUserDto === undefined) {
            throw new runtime.RequiredError('createSellerUserDto','Required parameter requestParameters.createSellerUserDto was null or undefined when calling createSellerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/users`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerUserDtoToJSON(requestParameters.createSellerUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerUserDtoFromJSON(jsonValue));
    }

    /**
     * Criar usuário do estabelecimento
     */
    async createSellerUser(requestParameters: CreateSellerUserRequest): Promise<GetSellerUserDto> {
        const response = await this.createSellerUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar estabelecimento
     */
    async createSellerV2Raw(requestParameters: CreateSellerV2Request): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.createSellerV2Dto === null || requestParameters.createSellerV2Dto === undefined) {
            throw new runtime.RequiredError('createSellerV2Dto','Required parameter requestParameters.createSellerV2Dto was null or undefined when calling createSellerV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/sellers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerV2DtoToJSON(requestParameters.createSellerV2Dto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Criar estabelecimento
     */
    async createSellerV2(requestParameters: CreateSellerV2Request): Promise<GetSellerDto> {
        const response = await this.createSellerV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação da lista de estabelecimentos
     */
    async createSellersListExportRequestRaw(requestParameters: CreateSellersListExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createExportSellersListDto === null || requestParameters.createExportSellersListDto === undefined) {
            throw new runtime.RequiredError('createExportSellersListDto','Required parameter requestParameters.createExportSellersListDto was null or undefined when calling createSellersListExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/list/export/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportSellersListDtoToJSON(requestParameters.createExportSellersListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação da lista de estabelecimentos
     */
    async createSellersListExportRequest(requestParameters: CreateSellersListExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createSellersListExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletar uma credencial de estabelecimento
     */
    async deleteSellerCredentialRaw(requestParameters: DeleteSellerCredentialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling deleteSellerCredential.');
        }

        if (requestParameters.credentialId === null || requestParameters.credentialId === undefined) {
            throw new runtime.RequiredError('credentialId','Required parameter requestParameters.credentialId was null or undefined when calling deleteSellerCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/credentials/{credentialId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"credentialId"}}`, encodeURIComponent(String(requestParameters.credentialId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletar uma credencial de estabelecimento
     */
    async deleteSellerCredential(requestParameters: DeleteSellerCredentialRequest): Promise<void> {
        await this.deleteSellerCredentialRaw(requestParameters);
    }

    /**
     * Editar estabelecimento
     */
    async editSellerRaw(requestParameters: EditSellerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling editSeller.');
        }

        if (requestParameters.editSellerDto === null || requestParameters.editSellerDto === undefined) {
            throw new runtime.RequiredError('editSellerDto','Required parameter requestParameters.editSellerDto was null or undefined when calling editSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditSellerDtoToJSON(requestParameters.editSellerDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Editar estabelecimento
     */
    async editSeller(requestParameters: EditSellerRequest): Promise<void> {
        await this.editSellerRaw(requestParameters);
    }

    /**
     * Editar uma credencial de estabelecimento
     */
    async editSellerCredentialRaw(requestParameters: EditSellerCredentialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling editSellerCredential.');
        }

        if (requestParameters.credentialId === null || requestParameters.credentialId === undefined) {
            throw new runtime.RequiredError('credentialId','Required parameter requestParameters.credentialId was null or undefined when calling editSellerCredential.');
        }

        if (requestParameters.editSellerCredentialDto === null || requestParameters.editSellerCredentialDto === undefined) {
            throw new runtime.RequiredError('editSellerCredentialDto','Required parameter requestParameters.editSellerCredentialDto was null or undefined when calling editSellerCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/credentials/{credentialId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"credentialId"}}`, encodeURIComponent(String(requestParameters.credentialId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditSellerCredentialDtoToJSON(requestParameters.editSellerCredentialDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Editar uma credencial de estabelecimento
     */
    async editSellerCredential(requestParameters: EditSellerCredentialRequest): Promise<void> {
        await this.editSellerCredentialRaw(requestParameters);
    }

    /**
     * Editar o seller parcialmente
     */
    async editSellerPartialRaw(requestParameters: EditSellerPartialRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling editSellerPartial.');
        }

        if (requestParameters.editSellerPartialDto === null || requestParameters.editSellerPartialDto === undefined) {
            throw new runtime.RequiredError('editSellerPartialDto','Required parameter requestParameters.editSellerPartialDto was null or undefined when calling editSellerPartial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditSellerPartialDtoToJSON(requestParameters.editSellerPartialDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Editar o seller parcialmente
     */
    async editSellerPartial(requestParameters: EditSellerPartialRequest): Promise<GetSellerDto> {
        const response = await this.editSellerPartialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os métodos de pagamento aceito pelo estabelecimento
     */
    async getAcceptedPaymentMethodsBySellerIdRaw(requestParameters: GetAcceptedPaymentMethodsBySellerIdRequest): Promise<runtime.ApiResponse<GetSellerAcceptedPaymentMethodsDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getAcceptedPaymentMethodsBySellerId.');
        }

        const queryParameters: any = {};

        if (requestParameters.valueCents !== undefined) {
            queryParameters['valueCents'] = requestParameters.valueCents;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/accepted-payment-methods`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerAcceptedPaymentMethodsDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os métodos de pagamento aceito pelo estabelecimento
     */
    async getAcceptedPaymentMethodsBySellerId(requestParameters: GetAcceptedPaymentMethodsBySellerIdRequest): Promise<GetSellerAcceptedPaymentMethodsDto> {
        const response = await this.getAcceptedPaymentMethodsBySellerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar resumo dos ultimos 6 meses de um Estabelecimentos
     */
    async getAllSellerSalesSummaryChartRaw(requestParameters: GetAllSellerSalesSummaryChartRequest): Promise<runtime.ApiResponse<GetSaleSummaryChartDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getAllSellerSalesSummaryChart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/summary-chart/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleSummaryChartDtoFromJSON(jsonValue));
    }

    /**
     * Listar resumo dos ultimos 6 meses de um Estabelecimentos
     */
    async getAllSellerSalesSummaryChart(requestParameters: GetAllSellerSalesSummaryChartRequest): Promise<GetSaleSummaryChartDto> {
        const response = await this.getAllSellerSalesSummaryChartRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar informações do cartório pelo cns
     */
    async getNotarysOfficesByCnsRaw(requestParameters: GetNotarysOfficesByCnsRequest): Promise<runtime.ApiResponse<NotaryOffice>> {
        if (requestParameters.cns === null || requestParameters.cns === undefined) {
            throw new runtime.RequiredError('cns','Required parameter requestParameters.cns was null or undefined when calling getNotarysOfficesByCns.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/get-notarys-office-data/{cns}`.replace(`{${"cns"}}`, encodeURIComponent(String(requestParameters.cns))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryOfficeFromJSON(jsonValue));
    }

    /**
     * Retornar informações do cartório pelo cns
     */
    async getNotarysOfficesByCns(requestParameters: GetNotarysOfficesByCnsRequest): Promise<NotaryOffice> {
        const response = await this.getNotarysOfficesByCnsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar as customizações de relatórios pelo estabelecimento
     */
    async getReportCustomizationBySellerIdRaw(requestParameters: GetReportCustomizationBySellerIdRequest): Promise<runtime.ApiResponse<GetSellerReportCustomizationDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getReportCustomizationBySellerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/report-customizations`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerReportCustomizationDtoFromJSON(jsonValue));
    }

    /**
     * Retornar as customizações de relatórios pelo estabelecimento
     */
    async getReportCustomizationBySellerId(requestParameters: GetReportCustomizationBySellerIdRequest): Promise<GetSellerReportCustomizationDto> {
        const response = await this.getReportCustomizationBySellerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Buscar venda pelo service id
     */
    async getSaleByServiceIdRaw(requestParameters: GetSaleByServiceIdRequest): Promise<runtime.ApiResponse<Array<GetSaleDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSaleByServiceId.');
        }

        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling getSaleByServiceId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/{serviceId}/service-id`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSaleDtoFromJSON));
    }

    /**
     * Buscar venda pelo service id
     */
    async getSaleByServiceId(requestParameters: GetSaleByServiceIdRequest): Promise<Array<GetSaleDto>> {
        const response = await this.getSaleByServiceIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do estabelecimento informado
     */
    async getSellerRaw(requestParameters: GetSellerRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do estabelecimento informado
     */
    async getSeller(requestParameters: GetSellerRequest): Promise<GetSellerDto> {
        const response = await this.getSellerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do estabelecimento informado pelo código
     */
    async getSellerByCodeRaw(requestParameters: GetSellerByCodeRequest): Promise<runtime.ApiResponse<GetSellerDto>> {
        if (requestParameters.codeId === null || requestParameters.codeId === undefined) {
            throw new runtime.RequiredError('codeId','Required parameter requestParameters.codeId was null or undefined when calling getSellerByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/code/{codeId}`.replace(`{${"codeId"}}`, encodeURIComponent(String(requestParameters.codeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do estabelecimento informado pelo código
     */
    async getSellerByCode(requestParameters: GetSellerByCodeRequest): Promise<GetSellerDto> {
        const response = await this.getSellerByCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do estabelecimento informado pelo Terminal Id
     */
    async getSellerByTerminalIdRaw(requestParameters: GetSellerByTerminalIdRequest): Promise<runtime.ApiResponse<GetSellerPosDto>> {
        if (requestParameters.terminalId === null || requestParameters.terminalId === undefined) {
            throw new runtime.RequiredError('terminalId','Required parameter requestParameters.terminalId was null or undefined when calling getSellerByTerminalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/code/terminal/{terminalId}`.replace(`{${"terminalId"}}`, encodeURIComponent(String(requestParameters.terminalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerPosDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do estabelecimento informado pelo Terminal Id
     */
    async getSellerByTerminalId(requestParameters: GetSellerByTerminalIdRequest): Promise<GetSellerPosDto> {
        const response = await this.getSellerByTerminalIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Buscar uma credencial
     */
    async getSellerCredentialRaw(requestParameters: GetSellerCredentialRequest): Promise<runtime.ApiResponse<GetSellerCredentialResponseDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerCredential.');
        }

        if (requestParameters.credentialId === null || requestParameters.credentialId === undefined) {
            throw new runtime.RequiredError('credentialId','Required parameter requestParameters.credentialId was null or undefined when calling getSellerCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/credentials/{credentialId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"credentialId"}}`, encodeURIComponent(String(requestParameters.credentialId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerCredentialResponseDtoFromJSON(jsonValue));
    }

    /**
     * Buscar uma credencial
     */
    async getSellerCredential(requestParameters: GetSellerCredentialRequest): Promise<GetSellerCredentialResponseDto> {
        const response = await this.getSellerCredentialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Busca informações do provedor de pagamento do seller
     */
    async getSellerPaymentProviderRaw(requestParameters: GetSellerPaymentProviderRequest): Promise<runtime.ApiResponse<GetSellerPaymentProviderDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerPaymentProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/payment-provider`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerPaymentProviderDtoFromJSON(jsonValue));
    }

    /**
     * Busca informações do provedor de pagamento do seller
     */
    async getSellerPaymentProvider(requestParameters: GetSellerPaymentProviderRequest): Promise<GetSellerPaymentProviderDto> {
        const response = await this.getSellerPaymentProviderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar os dados do POS informado
     */
    async getSellerPosRaw(requestParameters: GetSellerPosRequest): Promise<runtime.ApiResponse<GetSellerPosDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerPos.');
        }

        if (requestParameters.sellerPosId === null || requestParameters.sellerPosId === undefined) {
            throw new runtime.RequiredError('sellerPosId','Required parameter requestParameters.sellerPosId was null or undefined when calling getSellerPos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/pos/{sellerPosId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"sellerPosId"}}`, encodeURIComponent(String(requestParameters.sellerPosId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerPosDtoFromJSON(jsonValue));
    }

    /**
     * Retornar os dados do POS informado
     */
    async getSellerPos(requestParameters: GetSellerPosRequest): Promise<GetSellerPosDto> {
        const response = await this.getSellerPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar a venda informada
     */
    async getSellerSaleRaw(requestParameters: GetSellerSaleRequest): Promise<runtime.ApiResponse<GetSaleDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerSale.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling getSellerSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/{saleId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleDtoFromJSON(jsonValue));
    }

    /**
     * Retornar a venda informada
     */
    async getSellerSale(requestParameters: GetSellerSaleRequest): Promise<GetSaleDto> {
        const response = await this.getSellerSaleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar o boleto da venda informada
     */
    async getSellerSaleBilletRaw(requestParameters: GetSellerSaleBilletRequest): Promise<runtime.ApiResponse<GetBilletDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerSaleBillet.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling getSellerSaleBillet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/{saleId}/billets`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBilletDtoFromJSON(jsonValue));
    }

    /**
     * Retornar o boleto da venda informada
     */
    async getSellerSaleBillet(requestParameters: GetSellerSaleBilletRequest): Promise<GetBilletDto> {
        const response = await this.getSellerSaleBilletRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar o resumo de todas as vendas de um estabelecimento
     */
    async getSellerSalesSummaryRaw(requestParameters: GetSellerSalesSummaryRequest): Promise<runtime.ApiResponse<GetSaleSummaryDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerSalesSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/summary`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Listar o resumo de todas as vendas de um estabelecimento
     */
    async getSellerSalesSummary(requestParameters: GetSellerSalesSummaryRequest): Promise<GetSaleSummaryDto> {
        const response = await this.getSellerSalesSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retornar a venda informada
     */
    async getSellerSalev2Raw(requestParameters: GetSellerSalev2Request): Promise<runtime.ApiResponse<GetSaleWithAcquirerDocumentDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling getSellerSalev2.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling getSellerSalev2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/sellers/{sellerId}/sales/{saleId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleWithAcquirerDocumentDtoFromJSON(jsonValue));
    }

    /**
     * Retornar a venda informada
     */
    async getSellerSalev2(requestParameters: GetSellerSalev2Request): Promise<GetSaleWithAcquirerDocumentDto> {
        const response = await this.getSellerSalev2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Obter cartórios do responsável
     */
    async getSellersByResponsibleIdRaw(requestParameters: GetSellersByResponsibleIdRequest): Promise<runtime.ApiResponse<Array<GetSellerDto>>> {
        if (requestParameters.responsibleId === null || requestParameters.responsibleId === undefined) {
            throw new runtime.RequiredError('responsibleId','Required parameter requestParameters.responsibleId was null or undefined when calling getSellersByResponsibleId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/responsible/{responsibleId}`.replace(`{${"responsibleId"}}`, encodeURIComponent(String(requestParameters.responsibleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerDtoFromJSON));
    }

    /**
     * Obter cartórios do responsável
     */
    async getSellersByResponsibleId(requestParameters: GetSellersByResponsibleIdRequest): Promise<Array<GetSellerDto>> {
        const response = await this.getSellersByResponsibleIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async internalListSellerSalesRaw(requestParameters: InternalListSellerSalesRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling internalListSellerSales.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling internalListSellerSales.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling internalListSellerSales.');
        }

        const queryParameters: any = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.lastNumberSales !== undefined) {
            queryParameters['last_number_sales'] = requestParameters.lastNumberSales;
        }

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.payerEmail !== undefined) {
            queryParameters['payer_email'] = requestParameters.payerEmail;
        }

        if (requestParameters.payerPhone !== undefined) {
            queryParameters['payer_phone'] = requestParameters.payerPhone;
        }

        if (requestParameters.updatedDay !== undefined) {
            queryParameters['updated_day'] = requestParameters.updatedDay;
        }

        if (requestParameters.createdByUserName !== undefined) {
            queryParameters['created_by_user_name'] = requestParameters.createdByUserName;
        }

        if (requestParameters.pos) {
            queryParameters['pos'] = requestParameters.pos;
        }

        if (requestParameters.calcTotalAmount !== undefined) {
            queryParameters['calc_total_amount'] = requestParameters.calcTotalAmount;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/internal/list`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async internalListSellerSales(requestParameters: InternalListSellerSalesRequest): Promise<PaginatedDto> {
        const response = await this.internalListSellerSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os sellers do mesmo merchant
     */
    async listMerchantSellersRaw(requestParameters: ListMerchantSellersRequest): Promise<runtime.ApiResponse<Array<GetMerchantSellerDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listMerchantSellers.');
        }

        const queryParameters: any = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/list-sellers`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMerchantSellerDtoFromJSON));
    }

    /**
     * Listar os sellers do mesmo merchant
     */
    async listMerchantSellers(requestParameters: ListMerchantSellersRequest): Promise<Array<GetMerchantSellerDto>> {
        const response = await this.listMerchantSellersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar vendas de um terminal
     */
    async listPosSellerSalesRaw(requestParameters: ListPosSellerSalesRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listPosSellerSales.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listPosSellerSales.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listPosSellerSales.');
        }

        if (requestParameters.terminalId === null || requestParameters.terminalId === undefined) {
            throw new runtime.RequiredError('terminalId','Required parameter requestParameters.terminalId was null or undefined when calling listPosSellerSales.');
        }

        const queryParameters: any = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.lastNumberSales !== undefined) {
            queryParameters['last_number_sales'] = requestParameters.lastNumberSales;
        }

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.payerEmail !== undefined) {
            queryParameters['payer_email'] = requestParameters.payerEmail;
        }

        if (requestParameters.payerPhone !== undefined) {
            queryParameters['payer_phone'] = requestParameters.payerPhone;
        }

        if (requestParameters.settlementBatchDay !== undefined) {
            queryParameters['settlement_batch_day'] = requestParameters.settlementBatchDay;
        }

        if (requestParameters.updatedDay !== undefined) {
            queryParameters['updated_day'] = requestParameters.updatedDay;
        }

        if (requestParameters.createdByUserName !== undefined) {
            queryParameters['created_by_user_name'] = requestParameters.createdByUserName;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/pos/{terminalId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"terminalId"}}`, encodeURIComponent(String(requestParameters.terminalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar vendas de um terminal
     */
    async listPosSellerSales(requestParameters: ListPosSellerSalesRequest): Promise<PaginatedDto> {
        const response = await this.listPosSellerSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar as adquirentes do estabelecimento informado
     */
    async listSellerAcquirersRaw(requestParameters: ListSellerAcquirersRequest): Promise<runtime.ApiResponse<Array<GetAcquirerDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerAcquirers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/acquirers`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAcquirerDtoFromJSON));
    }

    /**
     * Listar as adquirentes do estabelecimento informado
     */
    async listSellerAcquirers(requestParameters: ListSellerAcquirersRequest): Promise<Array<GetAcquirerDto>> {
        const response = await this.listSellerAcquirersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os boletos do estabelecimento informado
     */
    async listSellerBilletsRaw(requestParameters: ListSellerBilletsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerBillets.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerBillets.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerBillets.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/billets`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os boletos do estabelecimento informado
     */
    async listSellerBillets(requestParameters: ListSellerBilletsRequest): Promise<PaginatedDto> {
        const response = await this.listSellerBilletsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar credenciais de um estabelecimento
     */
    async listSellerCredentialRaw(requestParameters: ListSellerCredentialRequest): Promise<runtime.ApiResponse<Array<GetSellerCredentialResponseDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerCredential.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/credentials`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerCredentialResponseDtoFromJSON));
    }

    /**
     * Listar credenciais de um estabelecimento
     */
    async listSellerCredential(requestParameters: ListSellerCredentialRequest): Promise<Array<GetSellerCredentialResponseDto>> {
        const response = await this.listSellerCredentialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os POS de um estabelecimento paginado
     */
    async listSellerPaginatedPosRaw(requestParameters: ListSellerPaginatedPosRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerPaginatedPos.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerPaginatedPos.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listSellerPaginatedPos.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerPaginatedPos.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/paginated/pos`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os POS de um estabelecimento paginado
     */
    async listSellerPaginatedPos(requestParameters: ListSellerPaginatedPosRequest): Promise<PaginatedDto> {
        const response = await this.listSellerPaginatedPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar um pagador do estabelecimento
     */
    async listSellerPayerRaw(requestParameters: ListSellerPayerRequest): Promise<runtime.ApiResponse<GetPayerDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerPayer.');
        }

        if (requestParameters.payerDocument === null || requestParameters.payerDocument === undefined) {
            throw new runtime.RequiredError('payerDocument','Required parameter requestParameters.payerDocument was null or undefined when calling listSellerPayer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/payers/{payerDocument}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"payerDocument"}}`, encodeURIComponent(String(requestParameters.payerDocument))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPayerDtoFromJSON(jsonValue));
    }

    /**
     * Listar um pagador do estabelecimento
     */
    async listSellerPayer(requestParameters: ListSellerPayerRequest): Promise<GetPayerDto> {
        const response = await this.listSellerPayerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar pagadores do estabelecimento
     */
    async listSellerPayersRaw(requestParameters: ListSellerPayersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerPayers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerPayers.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerPayers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.document !== undefined) {
            queryParameters['document'] = requestParameters.document;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/payers`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar pagadores do estabelecimento
     */
    async listSellerPayers(requestParameters: ListSellerPayersRequest): Promise<PaginatedDto> {
        const response = await this.listSellerPayersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os links de pagamento do estabelecimento informado
     */
    async listSellerPaymentLinksRaw(requestParameters: ListSellerPaymentLinksRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerPaymentLinks.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerPaymentLinks.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerPaymentLinks.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/payment-links`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os links de pagamento do estabelecimento informado
     */
    async listSellerPaymentLinks(requestParameters: ListSellerPaymentLinksRequest): Promise<PaginatedDto> {
        const response = await this.listSellerPaymentLinksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os POS de um estabelecimento
     */
    async listSellerPosRaw(requestParameters: ListSellerPosRequest): Promise<runtime.ApiResponse<Array<GetSellerPosDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerPos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/pos`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSellerPosDtoFromJSON));
    }

    /**
     * Listar os POS de um estabelecimento
     */
    async listSellerPos(requestParameters: ListSellerPosRequest): Promise<Array<GetSellerPosDto>> {
        const response = await this.listSellerPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os links de protesto do estabelecimento informado
     */
    async listSellerProtestLinksRaw(requestParameters: ListSellerProtestLinksRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerProtestLinks.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerProtestLinks.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerProtestLinks.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/protest-links`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os links de protesto do estabelecimento informado
     */
    async listSellerProtestLinks(requestParameters: ListSellerProtestLinksRequest): Promise<PaginatedDto> {
        const response = await this.listSellerProtestLinksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os responsaveis do estabelecimento informado
     */
    async listSellerResponsibleSupportByIdRaw(requestParameters: ListSellerResponsibleSupportByIdRequest): Promise<runtime.ApiResponse<GetSellerResponsibleSuportDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerResponsibleSupportById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/responsible-support`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerResponsibleSuportDtoFromJSON(jsonValue));
    }

    /**
     * Listar os responsaveis do estabelecimento informado
     */
    async listSellerResponsibleSupportById(requestParameters: ListSellerResponsibleSupportByIdRequest): Promise<GetSellerResponsibleSuportDto> {
        const response = await this.listSellerResponsibleSupportByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async listSellerSalesRaw(requestParameters: ListSellerSalesRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerSales.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerSales.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerSales.');
        }

        const queryParameters: any = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.lastNumberSales !== undefined) {
            queryParameters['last_number_sales'] = requestParameters.lastNumberSales;
        }

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.payerEmail !== undefined) {
            queryParameters['payer_email'] = requestParameters.payerEmail;
        }

        if (requestParameters.payerPhone !== undefined) {
            queryParameters['payer_phone'] = requestParameters.payerPhone;
        }

        if (requestParameters.settlementBatchDay !== undefined) {
            queryParameters['settlement_batch_day'] = requestParameters.settlementBatchDay;
        }

        if (requestParameters.updatedDay !== undefined) {
            queryParameters['updated_day'] = requestParameters.updatedDay;
        }

        if (requestParameters.createdByUserName !== undefined) {
            queryParameters['created_by_user_name'] = requestParameters.createdByUserName;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async listSellerSales(requestParameters: ListSellerSalesRequest): Promise<PaginatedDto> {
        const response = await this.listSellerSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async listSellerSalesV2Raw(requestParameters: ListSellerSalesV2Request): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerSalesV2.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerSalesV2.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerSalesV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.originalAmount !== undefined) {
            queryParameters['original_amount'] = requestParameters.originalAmount;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.payerName !== undefined) {
            queryParameters['payer_name'] = requestParameters.payerName;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.formPayment) {
            queryParameters['form_payment'] = requestParameters.formPayment;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.lastNumberSales !== undefined) {
            queryParameters['last_number_sales'] = requestParameters.lastNumberSales;
        }

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.payerEmail !== undefined) {
            queryParameters['payer_email'] = requestParameters.payerEmail;
        }

        if (requestParameters.payerPhone !== undefined) {
            queryParameters['payer_phone'] = requestParameters.payerPhone;
        }

        if (requestParameters.settlementBatchDay !== undefined) {
            queryParameters['settlement_batch_day'] = requestParameters.settlementBatchDay;
        }

        if (requestParameters.updatedDay !== undefined) {
            queryParameters['updated_day'] = requestParameters.updatedDay;
        }

        if (requestParameters.createdByUserName !== undefined) {
            queryParameters['created_by_user_name'] = requestParameters.createdByUserName;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/sellers/{sellerId}/sales`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar vendas de um estabelecimento
     */
    async listSellerSalesV2(requestParameters: ListSellerSalesV2Request): Promise<PaginatedDto> {
        const response = await this.listSellerSalesV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Listar os usuários do estabelecimento
     */
    async listSellerUsersRaw(requestParameters: ListSellerUsersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellerUsers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellerUsers.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSellerUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/users`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar os usuários do estabelecimento
     */
    async listSellerUsers(requestParameters: ListSellerUsersRequest): Promise<PaginatedDto> {
        const response = await this.listSellerUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar estabelecimentos
     */
    async listSellersRaw(requestParameters: ListSellersRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listSellers.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listSellers.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.document !== undefined) {
            queryParameters['document'] = requestParameters.document;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.billetProvider !== undefined) {
            queryParameters['billet_provider'] = requestParameters.billetProvider;
        }

        if (requestParameters.cns !== undefined) {
            queryParameters['cns'] = requestParameters.cns;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar estabelecimentos
     */
    async listSellers(requestParameters: ListSellersRequest): Promise<PaginatedDto> {
        const response = await this.listSellersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Autorizar liquidação das vendas que estão aguardando confirmação
     */
    async newConfirmSalesRaw(requestParameters: NewConfirmSalesRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling newConfirmSales.');
        }

        if (requestParameters.confirmPaymentDto === null || requestParameters.confirmPaymentDto === undefined) {
            throw new runtime.RequiredError('confirmPaymentDto','Required parameter requestParameters.confirmPaymentDto was null or undefined when calling newConfirmSales.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/sellers/{sellerId}/sales/confirm`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPaymentDtoToJSON(requestParameters.confirmPaymentDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Autorizar liquidação das vendas que estão aguardando confirmação
     */
    async newConfirmSales(requestParameters: NewConfirmSalesRequest): Promise<object> {
        const response = await this.newConfirmSalesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Buscar em estabelecimentos
     */
    async searchSellerRaw(requestParameters: SearchSellerRequest): Promise<runtime.ApiResponse<Array<SearchSellerDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchSellerDtoFromJSON));
    }

    /**
     * Buscar em estabelecimentos
     */
    async searchSeller(requestParameters: SearchSellerRequest): Promise<Array<SearchSellerDto>> {
        const response = await this.searchSellerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Splitar uma venda pelos estabelecimentos informados
     */
    async splitSaleRaw(requestParameters: SplitSaleRequest): Promise<runtime.ApiResponse<SplitRulesDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling splitSale.');
        }

        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling splitSale.');
        }

        if (requestParameters.createSplitRulesDto === null || requestParameters.createSplitRulesDto === undefined) {
            throw new runtime.RequiredError('createSplitRulesDto','Required parameter requestParameters.createSplitRulesDto was null or undefined when calling splitSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/sales/{saleId}/split`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createSplitRulesDto.map(CreateSplitRulesDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SplitRulesDtoFromJSON(jsonValue));
    }

    /**
     * Splitar uma venda pelos estabelecimentos informados
     */
    async splitSale(requestParameters: SplitSaleRequest): Promise<SplitRulesDto> {
        const response = await this.splitSaleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar o tema do estabelecimento
     */
    async updateSellerThemeRaw(requestParameters: UpdateSellerThemeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling updateSellerTheme.');
        }

        if (requestParameters.updateSellerThemeDto === null || requestParameters.updateSellerThemeDto === undefined) {
            throw new runtime.RequiredError('updateSellerThemeDto','Required parameter requestParameters.updateSellerThemeDto was null or undefined when calling updateSellerTheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/theme`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerThemeDtoToJSON(requestParameters.updateSellerThemeDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualizar o tema do estabelecimento
     */
    async updateSellerTheme(requestParameters: UpdateSellerThemeRequest): Promise<void> {
        await this.updateSellerThemeRaw(requestParameters);
    }

    /**
     * Atualizar um usuário do estabelecimento
     */
    async updateSellerUserRaw(requestParameters: UpdateSellerUserRequest): Promise<runtime.ApiResponse<GetSellerUserDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling updateSellerUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateSellerUser.');
        }

        if (requestParameters.updateSellerUserDto === null || requestParameters.updateSellerUserDto === undefined) {
            throw new runtime.RequiredError('updateSellerUserDto','Required parameter requestParameters.updateSellerUserDto was null or undefined when calling updateSellerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers/{sellerId}/users/{userId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerUserDtoToJSON(requestParameters.updateSellerUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSellerUserDtoFromJSON(jsonValue));
    }

    /**
     * Atualizar um usuário do estabelecimento
     */
    async updateSellerUser(requestParameters: UpdateSellerUserRequest): Promise<GetSellerUserDto> {
        const response = await this.updateSellerUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar upload da logo da maquininha android do estabelecimento
     */
    async uploadSellerImagePosRaw(requestParameters: UploadSellerImagePosRequest): Promise<runtime.ApiResponse<GetImagePosDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling uploadSellerImagePos.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadSellerImagePos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/image_pos`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetImagePosDtoFromJSON(jsonValue));
    }

    /**
     * Realizar upload da logo da maquininha android do estabelecimento
     */
    async uploadSellerImagePos(requestParameters: UploadSellerImagePosRequest): Promise<GetImagePosDto> {
        const response = await this.uploadSellerImagePosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Realizar upload da logo do estabelecimento
     */
    async uploadSellerLogoRaw(requestParameters: UploadSellerLogoRequest): Promise<runtime.ApiResponse<GetLogoDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling uploadSellerLogo.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadSellerLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/v1/sellers/{sellerId}/logo`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLogoDtoFromJSON(jsonValue));
    }

    /**
     * Realizar upload da logo do estabelecimento
     */
    async uploadSellerLogo(requestParameters: UploadSellerLogoRequest): Promise<GetLogoDto> {
        const response = await this.uploadSellerLogoRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetSellerSalesSummaryStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum InternalListSellerSalesTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum InternalListSellerSalesSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum InternalListSellerSalesStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPosSellerSalesTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPosSellerSalesSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPosSellerSalesStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerBilletsSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerPaginatedPosStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerPaginatedPosSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerPayersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerPaymentLinksStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerPaymentLinksFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerProtestLinksStatusEnum {
    Active = 'active',
    PendingCapture = 'pending_capture',
    Expired = 'expired',
    Canceled = 'canceled',
    Paid = 'paid'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerProtestLinksFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Billet = 'billet',
    Pix = 'pix',
    Pending = 'pending',
    PixPos = 'pix_pos'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesV2TypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesV2SortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerSalesV2StatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn',
    Refused = 'refused'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellerUsersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellersSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellersStatusEnum {
    Activated = 'activated',
    Inactive = 'inactive',
    Pending = 'pending',
    ActivationSent = 'activation_sent'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellersModeEnum {
    Raw = 'raw',
    Full = 'full',
    RawWithSummary = 'raw_with_summary'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSellersBilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau'
}
