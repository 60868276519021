import React from 'react';

import { Flex, Box, FormControl, Input, Stack, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';

import * as yup from 'yup';

import NewModal from 'containers/NewModal';

import Button from 'components/Button';
import FormInput from 'components/Form/FormInput';
import FormLabel from 'components/Form/FormLabel';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';
import { SellerSelectHandle } from 'components/SellerSelect/SellerSelect';
import SellerSelect from 'components/SellerSelect';

import { getApiAuthConfig } from 'services/api.service';

import {
	SellersApi,
	GetSellerDto,
	CreateSellerPosRequest,
	AcquirersApi,
	GetAcquirerDto,
	UpdateBackofficePosRequest,
	PosApi,
} from 'clients';

import { pos_status } from 'config/constants';
import { listPosStatus } from 'services/enums.service';

const createPosSchema = yup.object().shape({
	terminal_id: yup.string().required('Campo obrigatório'),
	acquirer_id: yup.string().required('Campo obrigatório'),
	status: yup.string(),
});

const editPosSchema = yup.object().shape({
	terminal_id: yup.string(),
	acquirer_id: yup.string(),
	status: yup.string(),
});

type BackofficeModalProps = {
	openPosModal: boolean;
	seller?: GetSellerDto;
	showSellerInput: boolean;
	handleOpenPosModal(id?: string): void;
	isLoading: boolean;
	setIsLoading(load: boolean): void;
	resetTable: boolean;
	setResetTable(load: boolean): void;
	posID?: string;
	selectedPos?: any;
};

const BackofficePOSModal: React.FC<BackofficeModalProps> = ({
	openPosModal,
	seller,
	showSellerInput,
	handleOpenPosModal,
	isLoading,
	setIsLoading,
	resetTable,
	setResetTable,
	posID,
	selectedPos,
}) => {
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const acquirersApi = new AcquirersApi(apiConfig);
	const posApi = new PosApi(apiConfig);

	const [acquirers, setAcquirers] = React.useState<Array<GetAcquirerDto>>();
	const [selectedSeller, setSelectedSeller] = React.useState<GetSellerDto | undefined>();

	const ref = React.useRef<SellerSelectHandle>(null);

	const createPosInitialValues = {
		terminal_id: '',
		acquirer_id: '',
		status: '',
	};

	const editPosInitialValues = {
		terminal_id: selectedPos?.terminal_id.split('-').length ? selectedPos?.terminal_id.split('-')[1] : selectedPos?.terminal_id,
		acquirer_id: selectedPos?.acquirer_id,
		status: selectedPos?.status,
	};

	const handleClearCurrentSeller = () => {
		ref.current?.clear();

		setSelectedSeller(undefined);
	};

	const createPos = async (values, { resetForm }) => {
		setIsLoading(true);

		try {
			const requestParams: CreateSellerPosRequest = {
				sellerId: seller?.id!,
				createSellerPosDto: {
					terminal_id: 'V240mPlus-' + values.terminal_id,
					acquirer_id: values.acquirer_id,
					status: values.pos_status,
				},
			};

			await sellersApi.createSellerPos(requestParams);

			resetForm({});
			setResetTable(!resetTable);
			handleOpenPosModal();
		} catch (error) {
			addToast('Erro ao registrar máquina', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const editPos = async (values, { resetForm }) => {
		if (!posID) return;

		setIsLoading(true);

		try {
			const requestParams: UpdateBackofficePosRequest = {
				posId: posID,
				updateSellerPosDto: {
					seller_id: selectedSeller?.id,
					terminal_id: 'V240mPlus-' + values.terminal_id,
					acquirer_id: values.acquirer_id,
					status: values.pos_status,
				},
			};

			await posApi.updateBackofficePos(requestParams);

			resetForm({});
			handleClearCurrentSeller();
			setResetTable(!resetTable);
			handleOpenPosModal();
		} catch (error) {
			addToast('Erro ao editar máquina', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeSeller = (selectSeller: GetSellerDto) => {
		setSelectedSeller(selectSeller);
	};

	React.useEffect(() => {
		(async function () {
			try {
				setAcquirers(await acquirersApi.listAcquirers());
			} catch (error) {
				addToast('Erro ao carregar Adquirentes', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NewModal
			isOpen={openPosModal}
			size={`lg`}
			onClose={() => {
				handleOpenPosModal();
			}}
		>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex px={4} py={4} flexDirection='column'>
					{!posID ? (
						<>
							<Formik enableReinitialize initialValues={createPosInitialValues} validationSchema={createPosSchema} onSubmit={createPos}>
								{({ handleSubmit, isValid, values }) => {
									return (
										<form onSubmit={handleSubmit} style={{ width: '100%' }}>
											<Stack px={4} my={4} spacing={2}>
												{showSellerInput && (
													<FormControl>
														<FormLabel id='seller-form-label' fontSize={`md`} fontWeight='bold'>
															Estabelecimento
														</FormLabel>
														<Input
															id='seller-input'
															isDisabled
															size={`md`}
															color={`gray.700`}
															_hover={{ outine: 'none' }}
															_focus={{ outline: 'none' }}
															value={seller?.name!}
														/>
													</FormControl>
												)}
												<FormControl id='terminal_id' isRequired>
													<FormLabel id='terminal-id-form-label' fontSize={`md`} fontWeight='bold'></FormLabel>
													<InputGroup>
														<InputLeftAddon bgColor='default' color='white' children='V240mPlus-' mr={1} />
														<FormInput id='terminal-id-backoffice-pos-id-form-input' bgColor={`white`} size={`md`} name='terminal_id' />
													</InputGroup>
													<FormErrorMessage id='backoffice-terminal-id-form-error-text' name='terminal_id' />
												</FormControl>

												<FormControl id='acquirer_id' isRequired>
													<FormLabel id='acquirer-form-label'>Adquirente</FormLabel>
													<FormSelect id='pos-id-backoffice-acquirer-id-form-select' name='acquirer_id'>
														<option value='' />
														{acquirers?.map((acquirer) => (
															<option value={acquirer.id} key={acquirer.id}>
																{acquirer.name}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='backoffice-acquirer-id-form-error-text' name='acquirer_id' />
												</FormControl>

												<FormControl id='pos_status'>
													<FormLabel id='status-form-label'>Status</FormLabel>
													<FormSelect id='pos-id-backoffice-pos-status-form-select' name='pos_status'>
														<option value='' />
														{pos_status?.map((pos) => (
															<option value={pos.value} key={pos.key}>
																{listPosStatus[pos.text]}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='backoffice-pos-status-form-error-text' name='pos_status' />
												</FormControl>
											</Stack>

											<Button id='add-machine-button' size='md' isFullWidth disabled={!isValid} type='submit' isLoading={isLoading}>
												Inserir Máquina
											</Button>
										</form>
									);
								}}
							</Formik>
						</>
					) : (
						<>
							<Formik enableReinitialize initialValues={editPosInitialValues} validationSchema={editPosSchema} onSubmit={editPos}>
								{({ handleSubmit, isValid }) => {
									return (
										<form onSubmit={handleSubmit} style={{ width: '100%' }}>
											<Stack px={4} my={4} spacing={2}>
												<FormControl>
													<FormLabel id='no-pos-id-seller-form-label' fontSize={`md`} fontWeight='bold'>
														Estabelecimento
													</FormLabel>
													<SellerSelect ref={ref} onChange={handleChangeSeller} />
												</FormControl>
												<FormControl id='terminal_id'>
													<FormLabel id='no-pos-id-terminal-form-label' fontSize={`md`} fontWeight='bold'>
														Terminal
													</FormLabel>
													<InputGroup>
														<InputLeftAddon bgColor='default' color='white' children='V240mPlus-' mr={1} />
														<FormInput id='terminal-id-backoffice-no-pos-id-form-input' bgColor={`white`} size={`md`} name='terminal_id' />
													</InputGroup>
													<FormErrorMessage id='no-pos-id-backoffice-terminal-id-form-error-text' name='terminal_id' />
												</FormControl>

												<FormControl id='acquirer_id'>
													<FormLabel id='no-pos-id-acquirer-id-form-label' >Adquirente</FormLabel>
													<FormSelect id='no-pos-id-backoffice-acquirer-id-form-select' name='acquirer_id'>
														<option value='' />
														{acquirers?.map((acquirer) => (
															<option value={acquirer.id} key={acquirer.id}>
																{acquirer.name}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='no-pos-id-backoffice-aquirer-id-form-error-text' name='acquirer_id' />
												</FormControl>

												<FormControl id='pos_status'>
													<FormLabel id='no-pos-id-status-form-label'>Status</FormLabel>
													<FormSelect id='no-pos-id-backoffice-pos-status-form-select' name='pos_status'>
														<option value='' />
														{pos_status?.map((pos) => (
															<option value={pos.value} key={pos.key}>
																{listPosStatus[pos.text]}
															</option>
														))}
													</FormSelect>
													<FormErrorMessage id='no-pos-id-backoffice-pos-status-form-error-text' name='pos_status' />
												</FormControl>
											</Stack>

											<Button id='update-machines-button' size='lg' isFullWidth disabled={!isValid} type='submit' isLoading={isLoading}>
												Atualizar Máquinas
											</Button>
										</form>
									);
								}}
							</Formik>
						</>
					)}
				</Flex>
			</Box>
		</NewModal>
	);
};

export default BackofficePOSModal;
