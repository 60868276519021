import React from 'react';
import Select from 'react-select';
import { Box, HStack, Flex, Stack } from '@chakra-ui/layout';
import Checkout from '@parcelaexpress/checkout-react-component';
import { maskMoney, maskPorcent } from '../../../services/masks.service';
import {
	CreatePaymentV3Dto,
	CreatePaymentV3Request,
	GetPaymentLinkSellerDto,
	GetSimulationDto,
	PaymentsApi,
	CreatePaymentWithSplitDto,
	CreatePaymentWithSplitRequest,
} from '../../../clients';
import { CheckoutSaleInformationType } from '../types/checkout.type';
import CreditCardModal from './CreditCardModal';
import ConfirmationBox from './ConfirmationBox';
import yup from 'services/yup.service';
import { useToasts } from 'react-toast-notifications';
import { getApiDefaultConfig } from 'services/api.service';
import { form_payments } from 'config/constants';
import { Formik } from 'formik';
import PaymentSecureModal from './PaymentSecureModal';
import PayerForm from './PayerForm';
import { Button, FormControl } from '@chakra-ui/react';
import { FaLock } from 'react-icons/fa';
import Text from '../../../components/Text';

type CreditCardProps = {
	seller: GetPaymentLinkSellerDto;
	hasPreCapture?: boolean;
	simulationValues: Array<GetSimulationDto>;
	saleInformation: CheckoutSaleInformationType | undefined;
	setLinkPayed(payed: boolean): void;
	updatePaymentLink: () => void;
};

const paymentTypesLiterals = {
	credit: 'Crédito',
	debit: 'Débito',
	online_debit: 'Débito',
};

const schemaExtended = yup.object().shape({
	cpf: yup.string().trim().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
	name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
	cep: yup.string().trim().required('Campo obrigatório'),
	city: yup.string().trim().required('Campo obrigatório'),
	state: yup.string().trim().required('Campo obrigatório'),
	street: yup.string().trim().required('Campo obrigatório'),
	district: yup.string().trim().required('Campo obrigatório'),
	number: yup.string().trim().required('Campo obrigatório'),
	complement: yup.string(),
	email: yup.string().required('Campo obrigatório').email('E-mail inválido!'),
	phone: yup.string().required('Campo obrigatório'),
	birth_date: yup.string().required('Campo obrigatório'),
});

const schemaShort = yup.object().shape({
	cpf: yup.string().trim().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
	name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
});

const CreditCard: React.FC<CreditCardProps> = (props: CreditCardProps) => {
	const { addToast } = useToasts();
	const apiConfig = getApiDefaultConfig();
	const [reset, setReset] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
	const [confirmPayment, setConfirmPayment] = React.useState(false);
	const [paymentSecure, setPaymentSecure] = React.useState(false);
	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
		installments: 1,
		form_payment: '',
		cet: 0,
	});
	const [creditCheckout, setCreditCheckout] = React.useState({
		holder_name: '',
		number: '',
		expiration_month: '',
		expiration_year: '',
		security_code: '',
	});
	const [secureData, setSecureData] = React.useState<any>({});
	const [isValidCard, setIsValidCard] = React.useState(false);

	const initialValues = {
		cpf: '',
		cep: '',
		city: '',
		state: '',
		street: '',
		district: '',
		number: '',
		country: '',
		complement: '',
	};
	const customMessage: string = props.seller.custom_checkout?.credit_card?.custom_block || '';
	const extendedForm = props?.seller?.form_link_extended === undefined ? true : props?.seller?.form_link_extended;

	const checkoutSubmitHandle = () => {
		setConfirmPayment(!confirmPayment);
	};

	const checkoutOnChangeHandle = (state) => {
		const { data, isValid } = state;
		const { paymentMethod } = data;

		setCreditCheckout({
			holder_name: paymentMethod.holderName,
			number: paymentMethod.encryptedCardNumber,
			expiration_month: paymentMethod.encryptedExpiryMonth,
			expiration_year: paymentMethod.encryptedExpiryYear,
			security_code: paymentMethod.encryptedSecurityCode,
		});

		setIsValidCard(isValid);
	};

	const isCompletedForm = () => {
		if (
			(props.seller?.three_ds_active && props?.seller?.min_three_ds && props?.seller?.min_three_ds < Number(value.simulated)) ||
			(props.seller?.merchant?.three_ds_active &&
				props.seller?.merchant?.min_three_ds &&
				props.seller?.merchant?.min_three_ds < Number(value.simulated)) ||
			extendedForm
		) {
			return true;
		}
		return false;
	};

	const selectSchema = isCompletedForm() ? schemaExtended : schemaShort;

	const handlePayment = async (values, { resetForm }) => {
		if (!value.simulated || !props.saleInformation) return;
		setIsLoading(true);

		const hasToBlockByPayerName = () => {
			if (
				props.saleInformation?.first_name.toLowerCase() !== creditCheckout.holder_name.split(' ')[0].toLowerCase() &&
				props.seller.card_payer_check &&
				!props.seller.card_payer_check_min_value
			) {
				return true;
			}
			if (
				props.saleInformation?.first_name.toLowerCase() !== creditCheckout.holder_name.split(' ')[0].toLowerCase() &&
				props.seller.card_payer_check &&
				props.seller.card_payer_check_min_value &&
				props.seller.card_payer_check_min_value <= value.simulated
			) {
				return true;
			}
			return false;
		};

		try {
			if (hasToBlockByPayerName()) {
				addToast(
					'Atencao! Para sua segurança, os pagamentos via cartao de credito no link serao aceitos somente em nome do titular. Caso nao seja o titular, gentileza realizar o pagamento via pix ou boleto.',
					{
						appearance: 'error',
						autoDismiss: true,
					}
				);
				setConfirmPayment(!confirmPayment);
				return;
			}

			if (isCompletedForm()) {
				const createPaymentV3Dto: CreatePaymentV3Dto = {
					amount_cents: Number(value.simulated),
					description: props.saleInformation.description,
					form_payment: form_payments[value.form_payment],
					card_attributes: {
						holder_name: creditCheckout.holder_name,
						number: creditCheckout.number,
						expiration_month: creditCheckout.expiration_month,
						expiration_year: creditCheckout.expiration_year,
						security_code: creditCheckout.security_code,
					},
					installment_plan: {
						number_installments: value.installments,
					},
					customer: {
						first_name: props?.saleInformation.first_name,
						last_name: props?.saleInformation.last_name,
						document: props.saleInformation?.document || values.cpf,
					},
					sale_id: props.saleInformation.id,
					shopper: {
						name: values.name.trim(),
						email: values.email.trim(),
						birth_date: values.birth_date,
						phone: values.phone.trim(),
						document: values.cpf,
						address: {
							city: values.city.trim(),
							house_number_or_name: values.number.trim(),
							postal_code: values.cep.replace('-', '').trim(),
							state_or_province: values.state.trim(),
							street: values.street,
							complement: values.complement !== '' ? values.complement.trim() : 'N/A',
							district: values.district.trim(),
						},
					},
				};

				if (!createPaymentV3Dto.customer.document) {
					delete createPaymentV3Dto.customer.document;
				}

				const ip = await fetch('https://api.ipify.org/?format=json')
					.then((response) => response.json())
					.then((data) => data.ip)
					.catch((error) => console.error(error));

				createPaymentV3Dto['customer'] = {
					...createPaymentV3Dto['customer'],
					email: props.saleInformation!.email,
					ip,
					billing_address: {
						city: values.city.trim(),
						country: 'BR',
						house_number_or_name: values.number.trim(),
						postal_code: values.cep.replace('-', '').trim(),
						state: values.state.trim(),
						street: values.street,
					},
				};

				if (typeof props.hasPreCapture !== 'undefined') {
					createPaymentV3Dto.pre_capture = props.hasPreCapture;
				}

				const createPaymentRequest: CreatePaymentV3Request = {
					sellerId: props.seller.id,
					createPaymentV3Dto,
				};

				const paymentsApi = new PaymentsApi(apiConfig);
				const response = await paymentsApi.createPaymentV3(createPaymentRequest);

				if (response.resultCode === 'Authorised') {
					await props.updatePaymentLink();
					addToast('Pagamento realizado com sucesso!', {
						appearance: 'success',
						autoDismiss: true,
					});
					setConfirmPayment(!confirmPayment);
					resetForm();
					props.setLinkPayed(true);
					return;
				}

				const { action } = response;

				if (
					action &&
					(action['type'] === 'threeDS2Fingerprint' || action['type'] === 'threeDS2Challenge' || action['type'] === 'redirect')
				) {
					setConfirmPayment(!confirmPayment);
					setSecureData(action);
					resetForm();
					setPaymentSecure(!paymentSecure);
					return;
				}
			}

			if (!isCompletedForm()) {
				const createPaymentV2Dto: CreatePaymentWithSplitDto = {
					amount_cents: Number(value.simulated),
					description: props.saleInformation.description,
					form_payment: form_payments[value.form_payment],
					card_attributes: {
						holder_name: creditCheckout.holder_name,
						number: creditCheckout.number,
						expiration_month: creditCheckout.expiration_month,
						expiration_year: creditCheckout.expiration_year,
						security_code: creditCheckout.security_code,
					},
					installment_plan: {
						number_installments: value.installments,
					},
					customer: {
						first_name: props?.saleInformation.first_name,
						last_name: props?.saleInformation.last_name,
						document: props.saleInformation?.document || values.cpf,
					},
					sale_id: props.saleInformation.id,
				};

				if (!createPaymentV2Dto.customer.document) {
					delete createPaymentV2Dto.customer.document;
				}

				if (typeof props.hasPreCapture !== 'undefined') {
					createPaymentV2Dto.pre_capture = props.hasPreCapture;
				}

				const createPaymentRequest: CreatePaymentWithSplitRequest = {
					sellerId: props.seller.id,
					createPaymentWithSplitDto: createPaymentV2Dto,
				};

				const paymentsApi = new PaymentsApi(apiConfig);
				const response = await paymentsApi.createPaymentWithSplit(createPaymentRequest);

				if (response.resultCode === 'Authorised') {
					await props.updatePaymentLink();
					addToast('Pagamento realizado com sucesso!', {
						appearance: 'success',
						autoDismiss: true,
					});
					setConfirmPayment(!confirmPayment);
					resetForm();
					props.setLinkPayed(true);
					return;
				}

				const { action } = response;

				if (
					action &&
					(action['type'] === 'threeDS2Fingerprint' || action['type'] === 'threeDS2Challenge' || action['type'] === 'redirect')
				) {
					setConfirmPayment(!confirmPayment);
					setSecureData(action);
					resetForm();
					setPaymentSecure(!paymentSecure);
					return;
				}
			}
		} catch (err) {
			addToast('Falha no pagamento! Verifique os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
			setConfirmPayment(!confirmPayment);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		setReset(!reset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.simulated]);

	return (
		<Box>
			<Select
				label='Opções de simulação'
				placeholder='Selecione o número de parcelas...'
				options={props.simulationValues
					.filter((installment) => installment.type === 'credit' || installment.type === 'online_debit')
					.sort((a, b) => b.type.localeCompare(a.type))
					.map((installment) => ({
						label: (
							<HStack justify='space-between'>
								<Text id='mask-installment-amount-cents-text'>
									<strong>
										{installment.installment} x no {paymentTypesLiterals[installment.type]}
									</strong>{' '}
									de R$ {maskMoney(installment.installment_amount_cents / 100)}
								</Text>

								<Text id='mask-installment-total-amount-cents-text'>
									{' '}
									<strong>Total:</strong> R$ {maskMoney(installment.total_amount_cents / 100)}
								</Text>
							</HStack>
						),
						value: installment,
					}))}
				onChange={({ value }) => {
					setValue({
						installments: value.installment,
						simulated: value.total_amount_cents,
						original: value.original_amount_cents,
						form_payment: value.type,
						cet: value.cet,
					});
				}}
			/>

			<Flex direction='column' mt={`8`}>
				<Stack direction='column' spacing='4' fontSize='2sm' bgColor='gray.200' rounded='md' p='4'>
					<Flex justifyContent='space-between'>
						<Text id='mask-value-original-text'>Valor original do serviço destinado ao cartório</Text>

						<Text id='data-mask-value-original-text'>{maskMoney(value.original / 100)}</Text>
					</Flex>
					<Flex justifyContent='space-between'>
						<Text id='financial-operation-cost-text'>* Custo financeiro da operação:</Text>

						<Text id='data-simulated-value-text'>{maskMoney((value.simulated - value.original) / 100)}</Text>
					</Flex>
					<Flex justifyContent='space-between'>
						<Text id='total-cost-text'>* Custo efetivo total:</Text>

						<Text id='data-mask-value-cet-text'>{maskPorcent(value.cet)}</Text>
					</Flex>

					<Box bgColor='gray.400' height='0.063rem' />

					<Flex justifyContent='space-between'>
						<Text id='simulated-payment-value-text'>Valor a ser pago com acréscimo</Text>

						<Text id='data-mask-simulated-value-text'>{maskMoney(value.simulated / 100)}</Text>
					</Flex>
					{value.installments > 12 && (
						<Flex justifyContent='space-between'>
							<Text id='alert-transaction-text' fontWeight='bold'>
								ATENÇÃO! Transações de 13 a 18X estão sujeitas a aprovação do banco emissor, não sendo a Parcela Express responsável
								diretamente por essa aprovação!
							</Text>
						</Flex>
					)}
				</Stack>
			</Flex>

			<Flex direction='column' alignItems='center' mt={`4`}>
				<ConfirmationBox
					isChecked={checked}
					onChange={() => {
						setChecked(!checked);
					}}
					customMessage={customMessage}
					message={`
					O pagamento será processado pela Parcela Express. Em sua fatura,
					será identificada pelo prefixo "PEX" ou "PEX - 
					${props?.seller?.extract_name ? props.seller.extract_name : 'Parcela Express'}",
					podendo existir variações de acordo com o Banco Emissor. 
					`}
					secondMessage='
					Através do presente instrumento, fica confirmada a operacão financeira destinada ao
					pagamento de emolumentos e outras despesas relacionadas ao Cartório. O titular do
					cartão confirma a transação realizada e renuncia, de forma irretratável, ao direito de
					cancelar ou contestar a operação junto à operadora de cartão de crédito.
					'
				/>
			</Flex>

			{checked && (
				<>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validationSchema={isCompletedForm() && selectSchema}
						validateOnMount
						onSubmit={handlePayment}
					>
						{({ handleSubmit, setFieldValue, isValid }) => {
							return (
								<form onSubmit={handleSubmit} id='payment' className='data__credit-card'>
									<Flex flexDirection='column' height='100%' display='block' mb={10}>
										{(isCompletedForm() || isValid) && <PayerForm setFieldValue={setFieldValue} formExtended={isCompletedForm()} values />}
										{(isCompletedForm() || isValid) && (
											<Flex w='100%' borderWidth={1} borderColor='#ccc' rounded='xl' p={10} display={checked ? 'block' : 'none'}>
												<Checkout
													environment={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_ENVIRONMENT}
													clientKey={process.env.REACT_APP_ADYEN_CHECKOUT_ONLINE_CLIENT_KEY}
													onSubmit={checkoutSubmitHandle}
													theme='outline'
													apiUrl={process.env.REACT_APP_BASE_URL}
													sellerKey={props.seller.id}
													showPayButton={false}
													onChange={checkoutOnChangeHandle}
												/>
												<FormControl mt={6}>
													<Button
														background='#233660'
														border='0'
														borderRadius='6px'
														color='#fff'
														cursor='pointer'
														height='48px'
														margin='0'
														padding='15px'
														textDecoration='none'
														width='100%'
														onClick={checkoutSubmitHandle}
														disabled={!isValidCard}
														leftIcon={<FaLock />}
														transition='background .3s ease-out, box-shadow .3s ease-out'
														_focus={{
															boxShadow: '0 0 0 2px #99c2ff',
															outline: '0',
														}}
														_hover={{
															background: '#1c3045',
															boxShadow: '0 0, 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14)',
														}}
													>
														Pagamento
													</Button>
												</FormControl>
											</Flex>
										)}
										<Text id='original-value-tax-tex' mt={2} fontSize='0.75rem'>
											* Sobre o valor original dos serviços do cartório, será acrescentada a taxa da administradora do cartão.
										</Text>
									</Flex>
									<CreditCardModal confirmPayment={confirmPayment} isLoading={isLoading} handleSubmit={handleSubmit} />
								</form>
							);
						}}
					</Formik>
				</>
			)}
			{(secureData['type'] === 'threeDS2Fingerprint' || secureData['type'] === 'redirect') && props.saleInformation && (
				<PaymentSecureModal
					paymentSecure={paymentSecure}
					action={secureData}
					setLinkPayed={props.setLinkPayed}
					setPaymentSecure={setPaymentSecure}
					sellerID={props.seller.id}
					saleID={props.saleInformation.id}
				/>
			)}
		</Box>
	);
};

export default CreditCard;
